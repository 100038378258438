import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import {
  FaArrowsAlt,
  FaBalanceScale,
  FaBed,
  FaBriefcaseMedical,
  FaChartPie,
  FaCity,
  FaCog,
  FaDove,
  FaGraduationCap,
  FaHandHoldingUsd,
  FaLandmark,
  FaLeaf,
  FaPeopleCarry,
  FaRegChartBar,
  FaTabletAlt,
  FaTractor,
} from 'react-icons/fa';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Section from '../components/section';
import SEO from '../components/seo';
import PageNavigator from '../components/pageNavigator';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';

const Summit2022 = ({ data, location }) => {
  const parentTitle =
    'The Centre for Social Justice and Council of Social Justice Champions presents:';
  const pageTitle =
    'The 3rd International Conference on Social Justice and the 4th Social Justice Summit';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <PageNavigator
        items={{
          welcome: 'Welcome',
          concept: 'Concept & Themes',
          programme: 'Programme',
          speakers: 'Speakers',          
          /* registration: 'Registration', */
          watch: 'Watch now',
          /* press: 'Press coverage', */
        }}       
      />
      <Section id="welcome">
        <h2>Welcome!</h2>
        <Img
          fluid={data.summit2022Banner.childImageSharp.fluid}
          alt="The 3rd International Conference on Social Justice and the 4th Social Justice Summit, 11-12 October 2022"
          css={tw`w-full`}
        />
        <hr css={tw`mt-3 mb-10`} />
        <h3>
        Theme: Restitution
        </h3>
        <p>The 3rd International Conference on Social Justice and the 4th Social Justice Summit hosted by The Centre for Social Justice at Stellenbosch University will be held at Boschendal Conference Centre in the Western Cape under the theme Restitution.</p>
        <p><strong>Conference Date:</strong> 11 October 2022, followed by the <strong>4th Annual Summit on Social Justice</strong> on 12 October 2022.</p>
        <p>Both the Social Justice Conference and Summit will take the form of a <strong>hybrid event</strong> with the option of attending either online or in-person at the <strong>Boschendal Conference Centre</strong> close to Stellenbosch in the Western Cape. The registration link and programme will be shared soon.</p>
        <p><strong>The Social Justice CONFERENCE (11 October)</strong> is an interdisciplinary event aiming to bring together scholars from a wide range of academic fields to deliberate on the theoretical underpinnings of restitution in a social justice context.</p>
        <p><strong>The Social Justice SUMMIT (12 October)</strong> aims to bring together stakeholders from academia, government, the judiciary, business, civil society, NGOs, and international stakeholders to confer on various aspects of advancing restitution rooted in the principles of social justice.</p>        
        <p>
        {/*<Button
          to="https://hopin.com/events/social-justice-summit"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Register now
      </Button>*/}
          <Button
          to="https://twitter.com/CFSJ_MPlan"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Follow updates
        </Button>
        </p>
        <p><SafeLink
          to="/downloads/events/2022-10-conference-framing-paper-restitution.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read the framing paper
        </SafeLink> &middot; <SafeLink
          to="/downloads/events/2022-10-conference-summit-media-release.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read the media release
        </SafeLink> &middot; <SafeLink
          to="/downloads/events/2022-09-boschendal-declaration.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read the Boschendal declaration
        </SafeLink>  
        </p>
        <hr css={tw`my-5`} />
        <h3>Social Justice Champion 2022</h3>
        <LeadText>
          <SafeLink to="/blog/2022/09/social-justice-champion" css={tw`border-b-2 border-maroon hover:text-maroon`}>Nominate your Social Justice Champion now.</SafeLink>
        </LeadText>
        <hr css={tw`my-5`} />
        <h3>Previous conferences and summits</h3>
        <LeadText>
          You can watch free recordings of previous summits and conferences on our website.      
          <ul>          
            <li><SafeLink to="/summit-2020/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2020 summit &rarr;</SafeLink></li>
            <li><SafeLink to="/summit-conference-2021/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2021 summit and conference &rarr;</SafeLink></li>
          </ul>
        </LeadText>        
      </Section>
      <Section id="concept" isShaded>
        <h2>Concept and themes</h2>
        <p css={tw`mb-10`}>
          <Button
            to="/downloads/events/2022-09-conference-concept-note.pdf"
            size="md"
            css={tw`mb-2 mr-2`}
          >
            Read the Conference concept note
          </Button>&nbsp;
          <Button
            to="/downloads/events/2022-09-summit-concept-note.pdf"
            size="md"
            css={tw`mb-2 mr-2`}
          >
            Read the Summit concept note
          </Button>
        </p>        
        <h3>The thematic areas of the Conference and Summit are:</h3>
        <ul>
          <li>Wealth, income and economic justice;</li>
          <li>Health and well-being, family and social life;</li>
          <li>Land, housing and spatial equality;</li>
          <li>Impact of digitisation and innovation on the media, education and epistemology.</li>
          <li>Public governance, democratic leadership and access to justice.</li>
        </ul>
      </Section>
      <Section id="programme">
        <h2>Programme</h2>           
        <Button
          to="/downloads/events/2022-10-summit-conference-programme.pdf"
          size="md"
          css={tw`mb-4`}
        >
          Download the programme &rarr;
        </Button>
        <p><SafeLink
          to="/downloads/events/2022-10-conference-framing-paper-restitution.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read the framing paper
        </SafeLink></p>
      </Section>
      <Section id="speakers" isShaded>
        <h2>Keynote Speakers</h2>
        <p>Vivian Kleynhans, one of the speakers at the Annual Social Justice Summit on 12 October, explains why she is looking forward to participate in the conversation on restitution.</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/r_CRgAcV5Uw?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>Listen to Writer Diana Ferrus' poem, <em>I have come to take you home: A tribute to Sarah Baartman</em>, be spoken at the conference and summit:</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/cBnz9nXG17E?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen css={tw`mb-5`}></iframe>
        <p><SafeLink
          to="/downloads/events/2022-10-the-poem-by-diana-ferrus.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read the poem
        </SafeLink> &middot; <SafeLink
          to="/downloads/events/2022-10-diana-ferrus-on-sarah.pdf"
          size="md"
          css={tw`border-b-2 border-maroon hover:text-maroon`}
        >
          Read background to the poem
        </SafeLink>
        </p>
        {/* <Button to="#" size="md" css={tw`mb-10`}>
          View the full list of speakers, with bios &rarr;
            </Button> 
        <h3>International Conference on Social Justice</h3>
        <Grid mdGridCols={2} lgGridCols={2}>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.choiceMakhetha.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Choice Makhetha</h3>
            <LeadText css={tw`mb-0`}>
              Senior Director: Student Affairs, Stellenbosch University
            </LeadText>
            <p>
              Dr. Choice Makhetha is the Senior Director: Student Affairs at
              Stellenbosch University as from 1 August 2019. She is deeply
              involved in institutional initiatives focusing on Gender-Based
              Violence and Transformation, among others. She also serves as a
              member of the Institutional Audit Committee (IAC) of the Higher
              Education Quality Committee (HEQC), reporting to the Council on
              Higher Education (CHE) since February 2020 - February 2023. She
              obtained her PhD in Political Science in 2003, at the University
              of Free State, in the field of Elections and Electoral Systems -
              South Africa's perspective, funded by the Mellon Foundation. Dr
              Makhetha was on a 19-month contract serving as the Transformation
              Advisor: Office of the Vice Chancellor, at the Central University
              of Technology (CUT), in the Free State Province (January 2018-July
              2019). Before then, she was the Vice Rector Student Affairs and
              External Relations at the University of the Free State (March 2012
              - May 2016) and was acting in the same post for a year (2011)
              before full appointment.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.dereshRamjugernath.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Deresh Ramjugernath</h3>
            <LeadText css={tw`mb-0`}>
              Deputy Vice-Chancellor: Learning and Teaching, Stellenbosch
              University
            </LeadText>
            <p>
              Professor Deresh Ramjugernath has a PhD in Engineering (Chemical)
              from the University of Natal. He is currently the Deputy
              Vice-Chancellor: Learning and Teaching at Stellenbosch University.
              Prior to his appoint at Stellenbosch University he was the Deputy
              Vice-Chancellor: Research and Pro Vice-Chancellor: Innovation,
              Commercialization and Entrepreneurship at the University of
              KwaZulu-Natal (UKZN). He was the holder of the DST/NRF South
              Africa Research Chair (SARChI): Fluorine Process Engineering and
              Separation Technology at UKZN and was appointed full professor of
              chemical engineering, at the age of 31, in January 2004 at UKZN.
              He has been the recipient of numerous awards and recognition,
              including the NRF President‚ Äôs Award in 2005; NSTF Awards in
              2006 and 2010; UKZN Vice-Chancellor‚ Äôs Research Awardee in 2010;
              honorary professorship from Durban University of Technology in
              2012; inducted as a member of the Academy of Science of South
              Africa in 2012; inducted as a fellow of the South African Academy
              of Engineers in 2013; South African Institute of Chemical
              Engineers Gold Medal in 2013; South African Institute of Chemical
              Engineers Innovation Award in 2013 (member of the team); listed by
              Thomson Reuters in 2013 as one of the most highly cited
              researchers; Fellow of the African Academy of Science in 2016;
              Fellow of the University of KwaZulu-Natal in 2017; and most
              recently the Universities South Africa Entrepreneurial Development
              in Higher Education Deputy Vice-Chancellor Award in 2020.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.haroonBhorat.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Haroon Bhorat</h3>
            <LeadText css={tw`mb-0`}>
              Director: Development Policy Research Unit, University of Cape
              Town
            </LeadText>
            <p>
              Haroon Bhorat is Professor of Economics and Director of the
              Development Policy Research Unit at the University of Cape Town.
              His research interests cover labour economics, poverty and income
              distribution. Haroon sits on the Presidential Economic Advisory
              Council (PEAC) and holds the highly prestigious National Research
              Chair under the theme of Economic Growth, Poverty and Inequality:
              Exploring the Interactions for South Africa. He is a Non-resident
              Senior Fellow at the Brookings Institution; a Fellow of the UCT
              College of Fellows; a Research Fellow at IZA, the Institute for
              the Study of Labour in Bonn; and an Honorary Research Fellow at
              the Human Sciences Council (HSRC). He sits on the editorial
              advisory board of the World Bank Economic Review, and is a Board
              Member of the National Research Foundation (NRF) and UNU World
              Institute for Development Economics Research (UNU-WIDER). Haroon
              consults with international organizations such as the ILO, the
              UNDP, the World Bank, Ratings Agencies and emerging market fund
              managers, and has undertaken extensive work for several South
              African government departments‚ and most notably the South African
              Department of Labour, the Presidency and the National Treasury. He
              served as an economic advisor to two past Ministers of Finance and
              previous Presidents Thabo Mbeki and Kgalema Motlanthe, formally
              serving on the Presidential Economic Advisory Panel.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.sharleneSwartz.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Sharlene Swartz</h3>
            <LeadText css={tw`mb-0`}>
              Division Executive: Inclusive Economic Development research
              programme, Human Sciences Research Council
            </LeadText>
            <p>
              Professor Sharlene Swartz is Division Executive of the Inclusive
              Economic Development research programme at the Human Sciences
              Research Council, an Adjunct Professor of Philosophy at the
              University of Fort Hare, and a former Associate Professor of
              Sociology at the University of Cape Town. She holds undergraduate
              degrees from the University of the Witwatersrand and the
              University of Zululand; a Master's degree from Harvard University
              and a PhD from the University of Cambridge. Her expertise and
              current research centres on the just inclusion of youth in a
              transforming society. She is currently completing a book on
              decolonising methodologies in educational research; is working on
              the concept of ‘navigational capacities’ for youth in adverse
              contexts; and is formulating the notion of ‘refracted economies’
              as a tool for thinking about livelihoods and the future of work in
              a digital age. She has over 80 academic publications. Her books
              include The Oxford Handbook of Global South Youth Studies (2022);
              Society, Research and Power (2022); Another Country: Everyday
              Social Restitution (2016); Ikasi: the moral ecology of South
              Africa’s township youth (2009); Teenage Tata: Voices of Young
              Fathers in South Africa (2009); Youth citizenship and the politics
              of belonging (2013); Moral eyes: Youth and justice in Cameroon,
              Nigeria, Sierra Leone and South Africa (2018) and Studying while
              black: Race, education and emancipation in South African
              universities (2018).
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.tshepoMadlingozi.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Tshepo Madlingozi</h3>
            <LeadText css={tw`mb-0`}>
              Director: Centre for Applied Legal Studies, Wits University
            </LeadText>
            <p>
              Tshepo Madlingozi is an Associate Professor and Director of the
              Centre for Applied Legal Studies at Wits University. He has
              master’s degrees in both Law and Sociology, and he received his
              PhD degree from Birkbeck, University of London. He is a Research
              Associate at the Chair for Critical Studies in Higher Education at
              Nelson Mandela University. He is the co-editor of South African
              Journal of Human Rights and part of the management team of
              Pretoria University Law Press. He is a co-editor of Symbol or
              Substance: Socio-economic Rights in South Africa (Cambridge UP)
              and a co-editor of Introduction to Law and Legal Skills in South
              Africa, 2nd Edition (Oxford UP South Africa). He is an advisory
              board member of the Journal of Human Rights Practice, and he sits
              on the boards of the following civil society organizations:
              Amandla.mobi; Centre for Human Rights, University of Free State;
              the Council for the Advancement of the South African Constitution;
              the Rural Democracy Trust; and the Mining-Affected Communities
              United in Action/Women-Affected by Mining Action. He is also a
              member of the Steering Committee of the African Coalition for
              Corporate Accountability (ACCA), and a member of the advisory
              board of Health Justice Initiative. For thirteen years (2015-2018)
              he worked with and for Khulumani Support Group, a 120 000-strong
              social movement of victims and survivors of Apartheid as National
              Advocacy Coordinator and later the Chairperson.
            </p>
          </div>
        </Grid>
        <h3>Social Justice Summit</h3>
        <Grid mdGridCols={2} lgGridCols={2}>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.aurelienLechevallier.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Aurélien Lechavellier</h3>
            <LeadText css={tw`mb-0`}>
              French Ambassador to South Africa
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.edwinCameron.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Justice Edwin Cameron</h3>
            <LeadText css={tw`mb-0`}>
              Former Judge: Constitutional Court of South Africa
            </LeadText>
            <p>
              Justice Edwin Cameron is a former judge of South Africa’s highest
              court, the Constitutional Court. During apartheid, he was a human
              rights lawyer. In 2000 he was awarded the Nelson Mandela Award for
              Health and Human Rights. Cameron has fought for LGBTI equality and
              was a fierce critic of former South African president Thabo
              Mbeki’s AIDS denialist policies. After retiring from the bench in
              2019, he was elected Chancellor of Stellenbosch University and
              appointed judicial inspector of correctional services.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.lorenzoFioramonti.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Lorenzo Fioramonti</h3>
            <LeadText css={tw`mb-0`}>
              Former Minister of Education, University and Research: Italy
            </LeadText>
            <p>
              Lorenzo Fioramonti is the former Minister of Education, University
              and Research and a member of the Chamber of Deputies in Italy. He
              is Full Professor of Political Economy at the University of
              Pretoria, where he founded the Centre for the Study of Governance
              Innovation and was Deputy Project Leader of the Future Africa
              initiative. Co-author of the Wellbeing Economy theory, he is also
              Extraordinary Professor at the School of Public Leadership of the
              University of Stellenbosch, Senior Fellow at the Centre for Social
              Investment of the University of Heidelberg and at the Hertie
              School of Governance (Germany) and Associate Fellow at the UN
              University.{' '}
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.nicolaSmit.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Nicola Smit</h3>
            <LeadText css={tw`mb-0`}>
              Dean and Professor of Law: Faculty of Law, Stellenbosch University
            </LeadText>
            <p>
              Nicola Smit started her academic career as a junior lecturer at
              UNISA. She joined the University of Johannesburg (then RAU) in
              1996 as a lecturer. She eventually progressed to full professor
              and also served as Vice Dean of the UJ Faculty of Law. In 2013 she
              joined the NWU Faculty of Law as Executive Dean in Potchefstroom.
              Prof Smit has been Dean and Professor of Law of the Faculty of Law
              at Stellenbosch since August 2017. Her areas of specialisation
              include labour law (both collective and individual) and social
              security law. Prof Smit is a NRF-rated researcher and she
              publishes widely and delivers keynotes and presentations at
              national and international conferences. Although Prof Smit does
              not currently teach at undergraduate level, she continues to
              supervise postgraduate students in the fields of labour and social
              security law. For a number of years, she has also taught on the
              Global Law Programme of the KU Leuven. Prof Smit is a member of
              the International Advisory Board of The International Journal of
              Labour Law and Industrial Relations, member of the editorial board
              of International Labor Rights Case Law Journal, and co-editor and
              co-author of various books, including Law@work, Principles and
              Practice of Labour Law, Social Security: A Legal Analysis,
              Introduction to Social Security, Age Discrimination and Labour Law
              and The Law of Commerce.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.nthabisengMoleko.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Nthabiseng Moleko</h3>
            <LeadText css={tw`mb-0`}>Development Economist</LeadText>
            <p>
              Dr. Nthabiseng Moleko is a Development Economist who is core
              faculty member at the University of Stellenbosch Business School
              (USB) where she teaches Economics and Statistics as a Senior
              Lecturer since 2017. She also serves as a Commissioner for the
              Commission for Gender Equality appointed by the President in 2017
              and is currently the Deputy Chairperson of the Commission. She
              completed her PhD in Development Finance at USB on Pension Funds
              and national development and is the first South African woman to
              be conferred a doctorate in this discipline. Dr. Moleko regularly
              appears across various network's programming on economic and
              business coverage as a thought leader.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.thembiSiweya.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Thembi Siweya</h3>
            <LeadText css={tw`mb-0`}>
              Deputy Minister in The Presidency, South Africa
            </LeadText>
            <p>
              Ms Thembi Rhulani Siweya is a youth activist and a philanthropist
              who is very passionate about promoting a culture of reading and
              writing. She is a graduate from Medunsa and is currently enrolled
              for an MBA Degree. She Matriculated in Saint Morgens English
              Medium School in Mokopane Limpopo province. She was introduced to
              politics at an early age through student activism. She served as
              the Chairperson of SRC Science Faculty in Medunsa (Medical
              University of South Africa) and also Treasurer General (TG) For
              the South African Student Congress (SASCO). In the year 2013, she
              served in the ANCYL Provincial Task Team (PTT) Sub-Committee on
              Communications. She has been a member of the National Executive
              Committee (NEC) of African National Congress Youth League (ANCYL)
              Since 2015 to date. A communicator in her own right, Ms Siweya has
              vast experience of working in the communications environment both
              as a civil servant and as a politician. She worked as the
              Spokesperson for the Youth Commission in Limpopo Province between
              2007 and 2010. She was a Communications Manager in the Office of
              Limpopo Premier between 2010 and 2016. Ms Siweya is an avid reader
              and a writer who has written numerous published opinion pieces on
              a variety of topics specifically about Africa and the world. She
              counts the likes of Chinua Achebe, Bessie Head and Mandla Langa,
              Among her favourite Authors. In 2014, she started African
              Unmasked, a cognitive movement established to deliberate on
              current affairs, especially issues relating to gender, health,
              education, governance, economics and Identity. African Unmasked
              encourages dialogue among Africans with the aim to facilitate
              interdependence amongst African states. Through African Unmasked,
              Ms Siweya has donated learning materials such as books, school
              uniform and shoes to learners in various schools in all the nine
              provinces of South Africa. She has also been able to donate relief
              parcels to the victims of cyclone Idai in Zimbabwe and Mozambique.
              Between April and May 2019. Ms Siweya is the Deputy Minister in
              The Presidency of the Republic of South Africa, the position she
              was appointed to on 30 May 2019. The Deputy Minister is also a
              Member of Parliament for the ANC.
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.ustaKaitesi.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Usta Kaitesi</h3>
            <LeadText css={tw`mb-0`}>CEO: Rwanda Governance Board</LeadText>
            <p>
              Dr. Usta Kaitesi is the Chief Executive Officer of the Rwanda
              Governance Board and a commissioner to the Rwanda Law Reform
              Commission. She previously served as Deputy Chief Executive
              Officer of the Rwanda Governance Board, the principal of the
              University of Rwanda College of Arts and Social Sciences, the Vice
              Chair of the Technical Commission to Support the Parliament in the
              revision of the Constitution of the Republic of Rwanda, vice-dean
              for postgraduate, and head of the department of Public Law as well
              as a lecturer at the former National University of Rwanda. Usta
              Kaitesi was also an advocate with the Rwanda Bar Association and a
              member of the East African Law Society. Dr. Usta Kaitesi sat on
              various Board of directors, National Commission and Advisory
              boards at the International and Nationally levels including;
              member of the International Advisory Board of the Transitional
              Justice Program of the Minerva Centre for Human Rights of the
              Hebrew University of Jerusalem, Vice Chair of the Board of
              Directors of the Rwanda Biomedical Centre, Board Member of the
              Rwanda Governance Board, Vice Chair of the Executive Council of
              the Inter-University Council for East Africa as well as member of
              the advisor.
            </p>
          </div>
        </Grid>*/}
      </Section>

      {/*<Section id="registration">
        <h2>Registration</h2>
        <LeadText>
          Both the Conference and Summit will be hybrid events with the option of attending either online or in-person at Boschendal Conference Centre which is close to Stellenbosch in the Western Cape. 
        </LeadText>
        <Button
          to="https://hopin.com/events/social-justice-summit"
          size="md"
        >
          Register now
        </Button>
      </Section>*/}

      <Section id="watch" isShaded>
        <h2>Watch now</h2>
        <LeadText>
          Experience the conference and summit by watching the playlist below. You can{' '}
          <SafeLink
            to="https://youtube.com/playlist?list=PLwTL48ihN7WZrfDgYTKEVp_IeW1fVbJqS"
            css={tw`border-b-2 border-maroon hover:text-maroon`}
          >
            browse all sessions
          </SafeLink>{' '}
          here or watch them below.
        </LeadText>
        <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
          <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLwTL48ihN7WZrfDgYTKEVp_IeW1fVbJqS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
        </div>
        <h3 css={tw`mt-10`}>Photos</h3>
        <p><SafeLink to="https://drive.google.com/drive/folders/164OT-kTe7k8-HypaaZwsjCzcVXIlJCGL" css={tw`border-b-2 border-maroon hover:text-maroon`}>See all the event photos here.</SafeLink></p>
      </Section>
      {/*
      <Section id="press" isShaded>
        <h2>Press coverage</h2>
        <LeadText>
          The 2020 Summit has generated significant coverage in the South
          African press. Here is a selection of the latest articles:
        </LeadText>
        <ol>
          <li>
            <SafeLink
              to="https://www.dailymaverick.co.za/article/2020-11-05-thuli-madonselas-m-plan-could-it-be-south-africas-answer-to-eradicating-poverty-and-inequality/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Thuli Madonsela’s ‘M Plan’ – could it be South Africa’s answer to
              eradicating poverty and inequality?
            </SafeLink>
            <br />
            <em>The Daily Maverick</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.news24.com/news24/southafrica/news/we-need-to-speak-honestly-about-incompetent-state-officials-prof-adam-habib-20201105"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              'We need to speak honestly about incompetent state officials' -
              Prof Adam Habib
            </SafeLink>
            <br />
            <em>News24</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.timeslive.co.za/news/south-africa/2020-11-05-the-poor-dont-need-handouts-they-need-empowerment-madonsela/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              The poor don't need handouts, they need empowerment: Madonsela
            </SafeLink>
            <br />
            <em>TimesLive</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.polity.org.za/article/law-trust-social-justice-launches-plan-to-eradicate-poverty-2020-11-04"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Law Trust Chair in Social Justice launches plan to eradicate
              poverty
            </SafeLink>
            <br />
            <em>Polity</em>, 04/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-argus.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Faith and Social Justice
            </SafeLink>
            <br />
            <em>Cape Argus</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-sowetan.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Madonsela sets her M-Plan in motion
            </SafeLink>
            <br />
            <em>Sowetan</em>, 05/11/2020
          </li>
        </ol>
          </Section> */}
    </Layout>
  );
};

export default Summit2022;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    summit2022Banner: file(relativePath: { eq: "conference-summit-2022-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edwinCameron: file(relativePath: { eq: "2022-10-edwin-cameron.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    } 
    site {
      siteMetadata {
        title
      }
    }
  }
`;
